
import { defineComponent, ref, watch } from "vue";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
interface PhonebookInterface {
  name: string;
}

export default defineComponent({
  name: "share-api-crud-modal",

  emits: ["refreshList"],
  props: {
    open: {
      type: Number,
      default: 0,
    },
    showPhonebook: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const route = useRoute();
    const store = useStore();
    const phonebookCrudModalRef = ref();
    const editId = ref(undefined);
    const errorsBag = ref({});
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const title = ref(translate("Create a new notebook"));
    const formRef = ref<null | HTMLFormElement>(null);
    const targetData = ref<PhonebookInterface>({
      name: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: translate("Please enter the notebook name"),
          trigger: "blur",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) return;
      if (!targetData.value.name) return;

      errorsBag.value = {};
      formRef.value.validate((valid) => {
        if (valid) {
          if (!submitButtonRef.value) {
            return;
          }

          //Disable button
          submitButtonRef.value.disabled = true;
          // Activate indicator
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");

          let data = {
            name: targetData.value.name,
          };
          let endpoint = "phonebook/create";
          if (editId.value) endpoint = `phonebook/${editId.value}`;

          ApiService.post(endpoint, data)
            .then(() => {
              hideModal(phonebookCrudModalRef.value);
              emit("refreshList");
            })
            .catch((e) => {
              store.commit(Mutations.SET_ERROR, e.response.data);
              if (e.response!.status === 400) {
                errorsBag.value = e.response.data.data;
              }
              ElNotification({
                title: translate("error"),
                message: translate("Error creating phonebook"),
                type: "error",
              });
            })
            .finally(() => {
              if (submitButtonRef.value) {
                submitButtonRef.value.disabled = false;
                submitButtonRef.value?.removeAttribute("data-kt-indicator");
              }
            });
        }
      });
    };

    watch(
      () => props.showPhonebook,
      (data) => {
        if (!data) {
          clear();
        }

        data = JSON.parse(JSON.stringify(data));
        if (!data || !data.name) {
          clear();
          return;
        }

        editId.value = data.id;
        targetData.value.name = data.name;
      }
    );

    const clear = () => {
      targetData.value = {
        name: "",
      };
    };
    return {
      rules,
      submitButtonRef,
      submit,
      formRef,
      targetData,
      errorsBag,
      hideModal,
      title,
      editId,
      phonebookCrudModalRef,
      translate,
      route,
    };
  },
});
