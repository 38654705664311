
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import ApiService from "@/core/services/ApiService";
import { statusColor } from "@/core/services/HelpersFun";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ElNotification } from "element-plus/es";
import Platform from "@/components/Platform.vue";
import EditSecretaryTag from "@/views/pages/services/secretary/simpleComponents/EditSecretaryTag.vue";
import CreateWelcomeSecretary from "@/views/pages/services/secretary/simpleComponents/CreateWelcomeSecretary.vue";
import ChangeSettings from "@/views/pages/services/secretary/simpleComponents/ChangeSettings.vue";
import CopySecretary from "@/views/pages/services/secretary/simpleComponents/CopySecretaryTag.vue";
import BlackListModal from "@/views/pages/services/secretary/simpleComponents/BlackListModal.vue";
import { useI18n } from "vue-i18n";
import { checkPackagePlatform } from "@/core/services/HelpersFun";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import UserAvatar from "@/components/UserAvatar.vue";
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia";
import { Servers } from "@/store/pinia/Servers";
import SecretaryPower from "@/components/SecretaryPower.vue";
import { Secretary } from "@/store/pinia/Secretary";

export default defineComponent({
  name: "kt-secretary-bot-list",
  components: {
    EditSecretaryTag,
    CreateWelcomeSecretary,
    ChangeSettings,
    Platform,
    CopySecretary,
    BlackListModal,
    UserAvatar,
    SecretaryPower,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const platformHolder = platforms();
    const { platformsList } = storeToRefs(platformHolder);

    const secretaryHolder = Secretary();
    const { secretaryList, loadingSecretaryList } =
      storeToRefs(secretaryHolder);
    const { fetchSecretary, deleteSecretary } = secretaryHolder;

    const route = useRoute();

    const store = useStore();
    const dialogs = ref({
      EditTag: false,
    });

    const selectedItem = ref({
      bot: [],
      secretary: [],
    });

    // get servers list
    const serverHolder = Servers();
    // const { fetchServersList } = serverHolder;
    const { serverList } = storeToRefs(serverHolder);

    onMounted(() => {
      // fetchServersList();
    });

    const changeSecretaryData = (
      bot_id,
      tag,
      secretary_status: null,
      platform: null
    ) => {
      ApiService.post(`bot/${bot_id}/change-tag`, {
        tag: tag,
        secretary_status: secretary_status,
        platform: platform,
      })
        .then(() => {
          fetchSecretary("Autoresponder");
          ElNotification({
            title: translate("done"),
            message: translate("done"),
            type: "success",
          });
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(translate("Autoresponder"), []);
      await filter_secretary("active");
    });

    const expiration_type = ref("active");
    const filter_secretary_list = ref([]);
    const filter_secretary = (type) => {
      if (type === "active") {
        filter_secretary_list.value = secretaryList.value.filter(
          (secr) => secr.bot.pay_status === "active"
        );
      } else {
        filter_secretary_list.value = secretaryList.value.filter(
          (secr) => secr.bot.pay_status === "expired"
        );
      }
    };

    watchEffect(async () => {
      if (platformsList.value && !secretaryList.value) {
        await fetchSecretary("Autoresponder");
        await filter_secretary("active");
      }
    });

    const reload = async () => {
      await fetchSecretary("Autoresponder");
      await filter_secretary("active");
    };

    return {
      secretaryList,
      loadingSecretaryList,
      fetchSecretary,
      statusColor,
      translate,
      filter_secretary,
      expiration_type,
      filter_secretary_list,
      deleteSecretary,
      reload,

      dialogs,

      selectedItem,
      changeSecretaryData,
      checkPackagePlatform,
      route,
      platformsList,

      serverHolder,
      serverList,
    };
  },
});
