
import { defineComponent, onMounted, ref, computed ,watch} from "vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
// import Notice from "@/components/Notice.vue";
export default defineComponent({
  name: "dialog-copy-tag",
  components: {
    // Notice,
  },
  props: {
    bot_id_is: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "",
    },
  },
  emits: ["update"],
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const store = useStore();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const blackListModal = ref<null | HTMLElement>(null);

    const loadingBlackList = ref(true);
    const blackList = ref([] as any[]);
    const phoneNumber = ref();

    const getBlackLists = () => {
      loadingBlackList.value = true;
      ApiService.setHeader();
      ApiService.get(`black-list`)
        .then(({ data }) => {
          blackList.value = data.data.black_list.data;
        })
        .catch(() => {
          ElNotification({
            title: translate("error"),
            message: translate("failed"),
            type: "error",
          });
        })
        .finally(() => {
          loadingBlackList.value = false;
        });
    };

    const AddToBlackList = () => {
      if (!phoneNumber.value) return;
      loadingBlackList.value = true;
      ApiService.post("black-list/add", { phone: phoneNumber.value })
        .then(() => {
          phoneNumber.value = "";
          getBlackLists();
        })
        .catch((data) => {
          store.commit(Mutations.SET_ERROR, data.response.data);
        });
    };

    const deleteFromBlackList = (id) => {

      loadingBlackList.value = true;
      ApiService.post(`black-list/${id}/delete`, {})
        .then(() => {
          getBlackLists();
        })
        .catch((data) => {
          store.commit(Mutations.SET_ERROR, data.response.data);
        });
    };

    const search = ref("");
    const filterTableData = computed(() =>

      blackList.value.filter(
        (data) =>
          !search.value ||
          data["phone"].toLowerCase().includes(search.value.toLowerCase())
      )
    );


    onMounted(() => {
      getBlackLists();
    });

    return {
      search,
      translate,
      submitButtonRef,
      blackListModal,
      loadingBlackList,
      blackList,
      phoneNumber,
      AddToBlackList,
      deleteFromBlackList,
      filterTableData,
    };
  },
});
