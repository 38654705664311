import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  ref: "blackListModal",
  id: "kt_modal_black_list",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header pt-5" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = {
  class: "btn btn-icon btn-sm ms-2",
  "data-bs-dismiss": "modal",
  "aria-label": "Close"
}
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = { class: "modal-body" }
const _hoisted_9 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_black_list_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_black_list_header",
  "data-kt-scroll-wrappers": "#kt_modal_black_list_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_10 = { class: "d-flex align-items-center my-5" }
const _hoisted_11 = { class: "table-responsive" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.translate("Black List")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", null, _toDisplayString(_ctx.translate(
                  "Autoresponder Black list means the list of phone numbers that you do not want the answering by Autoresponder"
                )), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.translate(
                 "Add the country code (for Iran: 98) to the first numbers"
                )), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.translate("The blacklist only works for WhatsApp")), 1),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_el_input, {
                modelValue: _ctx.phoneNumber,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneNumber) = $event)),
                type: "text",
                placeholder: _ctx.translate('phone number')
              }, null, 8, ["modelValue", "placeholder"]),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.AddToBlackList && _ctx.AddToBlackList(...args))),
                class: "btn btn-light-primary",
                type: "button",
                id: "button-addon1"
              }, _toDisplayString(_ctx.translate("Add")), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_el_table, {
                data: _ctx.filterTableData,
                border: "",
                style: {"width":"100%"},
                class: "text-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    align: "right",
                    label: "شماره موبایل",
                    prop: "phone"
                  }),
                  _createVNode(_component_el_table_column, { align: "left" }, {
                    header: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.search,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
                        placeholder: "جستجو",
                        size: "small"
                      }, null, 8, ["modelValue"])
                    ]),
                    default: _withCtx((scope) => [
                      _createElementVNode("button", {
                        onClick: ($event: any) => (_ctx.deleteFromBlackList(scope.row.id)),
                        class: "btn btn-sm btn-outline btn-outline-dashed btn-active-light-danger me-1 mb-1",
                        "active-class": "active"
                      }, _toDisplayString(_ctx.translate("delete")), 9, _hoisted_12)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"])
            ])
          ])
        ])
      ])
    ])
  ], 512))
}