
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { translate } from "@/core/services/HelpersFun";
import PhonebookCreateModal from "@/views/pages/other/phoneBook/component/PhonebookCreateModal.vue";

import { checkPackagePlatform } from "@/core/services/HelpersFun";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";
import FileViewer from "@/components/FileViewer.vue";
import Emoji from "@/components/Emoji.vue";
import UploadData from "@/components/uploadFiles/UploadFile.vue";

export default defineComponent({
  name: "dialog-welcome-message-tag",
  components: {
    FileViewer,
    Emoji,
    UploadData,
    PhonebookCreateModal,
  },
  props: {
    bots: {
      type: Object,
    },
  },
  emit: ["update"],
  setup(props, { emit }) {
    const store = useStore();
    const tag = ref("");
    const step = ref(1);
    const selectedBot = ref();

    const AllBotsHolder = AllBots();
    const { botList } = storeToRefs(AllBotsHolder);

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const createWellComeSecretary = ref<null | HTMLElement>(null);

    const data = ref({
      sendOnlyOnceFlag: false,
      tag: "",
      title: "",
      menu_key: "",
      sort: 0,
      is_default: 1,
      send_message_after: 0,
      add_to_phonebook: "",
      action: [
        {
          type: "text",
          text: "",
          file: undefined,
          with_file: false,
        },
      ],
    });

    const createSecretary = () => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      if (data.value.send_message_after) data.value.sendOnlyOnceFlag = true;
      data.value.tag = tag.value;
      data.value.title = tag.value;
      data.value.action = data.value.action.filter((item) => {
        return item.text !== "" || item.file !== undefined;
      });

      ApiService.post(
        `bot/${selectedBot.value.bot_id}/bot-menu/create`,
        data.value
      )
        .then(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
            step.value = 2;
            emit("update");
            // back to default
            data.value = {
              sendOnlyOnceFlag: false,
              tag: "",
              title: "",
              menu_key: "",
              sort: 0,
              is_default: 1,
              send_message_after: 0,
              add_to_phonebook: "",
              action: [
                {
                  type: "text",
                  text: "",
                  file: undefined,
                  with_file: false,
                },
              ],
            };
          }
        })
        .catch((e) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          store.commit(Mutations.SET_ERROR, e.response.data);
        });
    };
    const addNewItems = () => {
      data.value.action.push({
        type: "text",
        text: "",
        file: undefined,
        with_file: false,
      });
    };

    const removeItem = (index) => {
      data.value.action.splice(index, 1);
    };

    // add to phone book
    const addToPhonebookFlag = ref(false);
    const addToPhonebook = ref(undefined);
    const phonebooks = ref([]);
    const getPhonebooks = () => {
      let endPointUrl = "phonebook/all";
      ApiService.setHeader();
      ApiService.get(endPointUrl).then(({ data }) => {
        phonebooks.value = data.data.phonebooks;
      });
    };

    onMounted(() => {
      getPhonebooks();
    });

    const fileUploadedCallBack = (file, activeIndex) => {
      data.value.action[activeIndex].file = file.path;
      data.value.action[activeIndex].type = file.file_type;
    };

    const emojiHelper = ref({
      activeIndex: 0 as number,
      activeIndent: 0 as number,
    });

    const addEmoji = (emoji) => {
      data.value.action[emojiHelper.value.activeIndex].text += emoji;
    };

    return {
      step,
      tag,
      botList,
      selectedBot,
      translate,
      createSecretary,
      submitButtonRef,
      createWellComeSecretary,
      data,
      getPhonebooks,
      addNewItems,
      removeItem,

      // add to phone book
      addToPhonebookFlag,
      addToPhonebook,
      phonebooks,

      checkPackagePlatform,
      addEmoji,
      emojiHelper,
      fileUploadedCallBack,
    };
  },
});
